<template>
  <div>
    <eden-table-actions :title="title">
      <template slot="actions">
        <eden-periods :default-period="defaultPeriod" @set-period="setPeriod" />
      </template>
    </eden-table-actions>

    <template v-if="orders.length">
      <el-table :data="orders">
        <el-table-column width="100">
          <template slot="header">
            <div class="table--header">
              <span>Order Id</span>
            </div>
          </template>
          <template v-slot="scope">
            <div>
              <p>
                <router-link
                  class="text-primary"
                  :to="{
                    name: 'orders.order',
                    params: { id: scope.row.id },
                  }"
                >
                  {{ scope.row.id }}</router-link
                >
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="250">
          <template slot="header">
            <div class="table--header">
              <span>Name</span>
            </div>
          </template>
          <template v-slot="scope">
            <p>
              <router-link
                :to="{
                  name: 'customers.individual',
                  params: { id: scope.row.user_id },
                }"
              >
                {{ formatName(scope.row.customer) }}
              </router-link>
            </p>
            <span class="font-xsm text-grey-tertiary">
              {{ scope.row.gardener }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template slot="header">
            <div class="table--header">
              <span>Phone Number</span>
            </div>
          </template>
          <template v-slot="scope">
            <p>
              {{ formatPhoneNumber(scope.row.phone_number) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <div class="table--header">
              <span>Beauty Type</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ scope.row.beauty_type }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <div class="table--header">
              <span>Status</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              <el-tag :type="setType(scope.row.status)"
                >{{ formatText(scope.row.status) }}
              </el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <div class="table--header">
              <span>Location Area</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ scope.row.location }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="250">
          <template slot="header">
            <div class="table--header">
              <span>Address</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ scope.row.address }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <span>Date</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ scope.row.date }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <i class="el-icon-warning-outline mr-10"></i>
              <span>En route time</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ scope.row.enroute_time }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <i class="el-icon-warning-outline mr-10"></i>
              <span>Arrival time</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ scope.row.arrival_time }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <div class="table--header">
              <i class="el-icon-warning-outline mr-10"></i>
              <span>Completion time</span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">
              {{ scope.row.completion_time ? scope.row.completion_time : "-" }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <eden-content-empty :text="'No orders'" />
  </div>
</template>

<script>
export default {
  name: "DirectoryBeauticianOrders",
  data() {
    return {
      defaultPeriod: "today",
      period: "thisweek",
      orders: [],
    };
  },

  computed: {
    title() {
      return `${this.orders.length} Orders`;
    },
  },

  methods: {
    setPeriod({ period }) {
      this.period = period;
    },
  },
};
</script>
